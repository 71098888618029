import logo from "./logo.svg";
import "./App.css";
import album from "./images/album.png";
import clock from "./images/clock.png";
import mic from "./images/mic.png";
import iphone from "./images/screens.png";

function App() {
  return (
    <div className="App" dir="rtl">
      {/* <div className="banner"></div> */}
      <div className="content-wrapper">
        <div className="home row">
          <div>
            <div className="row icon-row">
              <img
                src="https://s3.us-east-2.amazonaws.com/assets.singit.io/logo.png"
                alt="logo"
              />
              <h1>SingIt</h1>
            </div>
            <h1>ברוכים הבאים לתוכנת הלומדה של SingIt בשיתוף פעולה עם Speasy</h1>
            <span className="subtext">
              בחבילה זו אתם הולכים לקבל 21 יום מתנה שלאחריה יתבצע חיוב חודשי
              אוטומטי 49.90 ש״ח , המנוי ניתן לביטול בכל עת לפני מועד החיוב.
            </span>
            <button type="button" className="main-cta">
              קנה עכשיו
            </button>
          </div>
          {/* <div className="screenshot"></div> */}
          <img src={iphone} className="screenshot" alt="screenshots" />
        </div>
        <section className="bonuses">
          <h2>הבונוסים שאתה מקבל בפלטפורמה:</h2>
          <ul className="pricing-wrapper row">
            <li className="pricing-item">
              <img src={mic} alt="icon" />
              <p>מיקרופון לדיבור וחיזוק התרגול</p>
              <div className="price">
                <span>עלות חודשית</span>
                <b>
                  <del>49.90 ש״ח</del> 0 ש״ח
                </b>
              </div>
            </li>
            <li className="pricing-item">
              <img src={clock} alt="icon" />
              <p>תרגולים בזמן אמת</p>
              <div className="price">
                <span>עלות חודשית</span>
                <b>
                  <del>29.90 ש״ח</del> 0 ש״ח
                </b>
              </div>
            </li>
            <li className="pricing-item">
              <img src={album} alt="icon" />
              <p>חבילת שירים ותרגולים בלתי מוגבלת</p>
              <div className="price">
                <span>עלות חודשית</span>
                <b>
                  <del>79.90 ש״ח</del> 0 ש״ח
                </b>
              </div>
            </li>
          </ul>
        </section>
        <section className="savings">
          <p>החיסכון שלך</p>
          <h3 className="price-bold"> 159.7 ש״ח + 21 יום מתנה</h3>
          <p>לנרשמים הראשונים מתוכנית Speasy</p>
        </section>
        <ul className="final-price">
          <h1>
            <del>210 ש״ח חבילה מלאה</del>
          </h1>
          <h1>
            חבילה מלאה + 21 יום מתנה (שיחוייב רק לאחר 21 של שימוש בתוכנה) 49.90
            ש״ח
          </h1>
        </ul>
        <button type="button" className="main-cta large-button">קנה עכשיו</button>
        <section>
          <span>
            במידה ואתם רוצים לבטל כל שעליכם לעשות זה לשלוח לנו מייל או ללחוץ על
            לחצן הביטול באפליקציה
          </span>
        </section>
        <section></section>
      </div>
    </div>
  );
}

export default App;
